import OBJ from "@/assets/modules/OBJMethodsClass"

export default class withURL {

constructor(tmpl) {
    
    this.tmpl = tmpl
    this.OBJ = new OBJ(tmpl)

}

getCurrentMenu = () =>  {
    return window.location.href.split('/')[parseInt(window.location.href.split('/').length)-1]
}

isLocalHost =   ()  =>  {
    let path = window.location.href
    let aPath = path.toString().split('/')

    if(aPath[2].toLowerCase().indexOf('localhost')>(-1)) 
        return aPath[2].split(':')[1]
    else
        return ''
}

getParameterFromURL = (key='') => {

    if(key.trim().length===0) return ''

    let aParam
    let returnValue = ""
    let path = window.location.href
    //console.log(path)
    let aPath = path.split('?')    

    if(this.OBJ.count(aPath)<2) return ''

    let aMembers = aPath[1].split('&') 
    
    for(let i=0; i<aMembers.length;++i) {
        aParam = aMembers[i].split("=")        
        if(key.trim().toLowerCase()===aParam[0].trim().toLowerCase()) {
            returnValue = aParam[1]
            break
        }            
    }
    return returnValue
}

addParameterInURL = (key='', value='') => {
    let test = new URL(window.location);
    test.searchParams.set(key, value);
    window.history.pushState({}, '', test)          
}    
}