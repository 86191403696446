export default class withOBJ {

constructor(tmpl) {this.tmpl = tmpl}

count = (oObject) => {
    if(typeof oObject === 'string') {
        if(oObject.trim().length===0)   return 0
        oObject = this.fromVuex(this.tmpl, oObject)
    }

    if(!oObject.length)  
        if(Object.keys(oObject).length > 0) 
            return 1 
        else 
            return 0
            
    return oObject.length
}

fromVuex = (template, VUEXName='') => {
    let VUEXObject = template.$store.state[VUEXName]
    let oReturnObject = []
    
    for(let VUEXRecord of VUEXObject) oReturnObject.push(VUEXRecord)

    return oReturnObject
}

}